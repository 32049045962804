//  libraries
import React from 'react'

//  components
import SEO from '../components/seo'
import Layout from 'components/layout'

export default () => {
  return (
    <Layout>
      <SEO title="Privacy Policy | Edge Logistics" />
      <div style={{ margin: 'auto 5rem auto' }}>
        <iframe
          id="privacy-policy"
          src="/privacy-policy.html"
          title="privacy policy"
          style={{
            width: '100%',
            height: '100vh',
            border: 'none',
            margin: '3rem auto'
          }}
        ></iframe>
      </div>
    </Layout>
  )
}
